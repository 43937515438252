// SuperHome.js
import React from 'react';
import './SuperHome.css';
import Home from './Home';
import Carrousel from './Carrousel';
import Results from"./Results";


const SuperHome = () => {
  return (
    <div className="superhome">
      <Home />
      <Carrousel />
    <Results/>
    
    </div>
  );
};

export default SuperHome;
