import React, { useEffect, useState } from 'react';
import './BlurryBackground.css'; // Asegúrate de tener el archivo de estilos para este componente

function BlurryBackground() {
  const [showBlur, setShowBlur] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 0) {
        setShowBlur(true);
      } else {
        setShowBlur(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return showBlur ? <div className="blurry-background"></div> : null;
}

export default BlurryBackground;
