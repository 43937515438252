/* First make sure that you have installed the package */
  
/* If you are using yarn */
// yarn add @calcom/embed-react



import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect, useState } from "react";

export default function MyApp() {
  const [calWidth, setCalWidth] = useState('100%');

  useEffect(()=>{
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const maxWidth = Math.min(windowWidth * 0.9, 1000); // Limit to 90% of window width or 1000px, whichever is smaller
      setCalWidth(maxWidth + 'px');
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the resize handler initially
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
      <Cal 
        calLink="cresify/30min"
        style={{ width: calWidth, height: '100%' }}
        config={{ layout: 'month_view' }}
      />
    </div>
  );
};



  
  