import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './NavBar.css';
import { motion } from 'framer-motion';

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
  
    <nav className="navbar">
      <div className="navbar__left">
        <ScrollLink
          to="home"

          smooth={true}
          offset={-70}
          duration={500}
          onSetActive={() => handleSetActive('home')}
        >
          <img src="/logo.png" alt="Logo" className="navbar__logo" />
        </ScrollLink>
      </div>
      <div className={`navbar__center ${showMenu ? 'show' : ''}`}>
        <ul className="navbar__links">
         
          <li>
            <ScrollLink
              to="services"
          
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('services')}
              className={activeSection === 'services' ? 'active' : ''}
              onClick={closeMenu}
            >
              Servicios
            </ScrollLink>
          </li>

          <li>
            <ScrollLink
              to="testimonials-section"
          
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('services')}
              className={activeSection === 'services' ? 'active' : ''}
              onClick={closeMenu}
            >
              Testimonios
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="pricing-title"
        
              smooth={true}s
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('home')}
              className={activeSection === 'home' ? 'active' : ''}
              onClick={closeMenu}
            >
              Precio
            </ScrollLink>
          </li>

          <li>
            <ScrollLink
              to="contact"
         
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('contact')}
              className={activeSection === 'contact' ? 'active' : ''}
              onClick={closeMenu}
            >
              Contacto
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="faqs-container"
        
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('faqs-container')}
              className={activeSection === 'faqs-container' ? 'active' : ''}
              onClick={closeMenu}
            >
              FAQs
            </ScrollLink>
          </li>
        </ul>
      </div>
      <div className="navbar__right">
        <ScrollLink
          to="contact"
   
          smooth={true}
          offset={-70}
          duration={500}
          onClick={closeMenu}
        >
          <button className="navbar__button">Asesoría Gratuita</button>
        </ScrollLink>
      </div>
      <div className="navbar__menu-toggle" onClick={toggleMenu}>
        <div className="navbar__menu-icon-container">
          <div className={`navbar__menu-icon ${showMenu ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="navbar__menu">
          <ul className="navbar__menu-links">
          
            <li>
              <ScrollLink
                to="services"
            
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                Servicios
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="contact"
              
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                Contacto
              </ScrollLink>
            </li>

            <li>
            <ScrollLink
              to="testimonials-section"
          
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('services')}
              className={activeSection === 'services' ? 'active' : ''}
              onClick={closeMenu}
            >
              Testimonios
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="pricing-title"
        
              smooth={true}s
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActive('home')}
              className={activeSection === 'home' ? 'active' : ''}
              onClick={closeMenu}
            >
              Precio
            </ScrollLink>
          </li>
            <li>
              <ScrollLink
                to="faqs"
           
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                FAQs
              </ScrollLink>
            </li>
          </ul>
          <ScrollLink
            to="contact"
           
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}
          >
            <button className="navbar__menu-button">Asesoría Gratuita</button>
          </ScrollLink>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
