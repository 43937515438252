import React from 'react';
import './Home.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <div className="home__content">
          <div className="home__text-column">
            <motion.h1
              className="home__title"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
             Encontramos <br />
              <span className="home__highlight1">Clientes Potenciales </span> <br />
              con <span className="home__highlight1">Tecnología</span>
            </motion.h1>
            <div className="home__subtitle-container">
              <motion.h2
                className="home__subtitle"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
               Nos especializamos en estrategias de outbound multicanal que combinan <span className="home__strong">cold calling y email.</span>
              </motion.h2>
            </div>
            <ScrollLink
                 to="results"
            >
            <motion.button
            
              className="home__button"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.7 }}
            >
           
           Multiplica tus clientes
            </motion.button>
            </ScrollLink>
          </div>
          <div className="home__video-column">
            <motion.img
              src="/imagen2.png"
              alt="Ventajas"
              className="home__image"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.9 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
