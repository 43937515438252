import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';
import { motion, useAnimation } from 'framer-motion';
import FourthlineImage from './fourthline.jpg'; // Asegúrate de que la ruta sea correcta
import IvolveImage from './ivolve.jpg'; // Asegúrate de que la ruta sea correcta
import EschbachImage from './MartinK.jpg'; // Asegúrate de que la ruta sea correcta
import './Testimonials.css';
import { Link as ScrollLink } from 'react-scroll';

const testimonials = [
  {
    id: 1,
    quote: "Desde que trabajamos con ellos, hemos tenido un aumento en leads cualificados gracias a su enfoque personalizado. Además, su flexibilidad para adaptar la estrategia según nuestras necesidades nos permitió optimizar resultados sin perder tiempo.",
    author: 'Martin K',
    role: 'General Manager',
    company: 'eschbach',
    logo: 'logo4escabch.svg',
    image: EschbachImage,
  },
  {
    id: 2,
    quote: "Su enfoque en outreach nos ayudó a lograr una alta conversión con mensajes bien dirigidos. La transparencia en cada etapa nos dio confianza y nos mantuvo siempre informados, lo que hizo que el proceso fuera mucho más efectivo.",
    author: 'John N',
    role: 'Chief Growth Officer',
    company: 'iVolve',
    logo: 'logo13ivolve.svg',
    image: IvolveImage,
  },
  {
    id: 3,
    quote: "Ellos ejecutaron nuestra estrategia de outbound que generó resultados sostenibles para nuestra empresa. Además, su comunicación constante fue clave para ajustar tácticas y asegurarnos de estar siempre alineados con nuestros objetivos.",
    author: 'Chris Van S',
    role: 'Chief Revenue Officer',
    company: 'Fourthline',
    logo: 'logo6fourthline.svg',
    image: FourthlineImage,
  },
];

const Testimonials = () => {
  const controls = useAnimation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('testimonials-section');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight * 0.8 && !isVisible) {
          setIsVisible(true);
          controls.start({ opacity: 1, x: 0 });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls, isVisible]);

  return (
    <div id="testimonials-section" className="testimonials-container">
      {/* Título animado */}
      <motion.h1
        className="services__title"
        initial={{ opacity: 0, x: -50 }}
        animate={controls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <span className="animate-text-gradient1">Casos de éxito:</span> Empresas que confían en nosotros
      </motion.h1>

      {/* Carrusel de testimonios con la misma animación pero diferente velocidad */}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={controls}
        transition={{ duration: 1, delay: 0.5 }}
        className="swiper-container"
      >
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={40}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          slidesPerView={1}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial-slide">
                <img src={testimonial.logo} alt={`${testimonial.company} logo`} className="company-logo" />
                <p className="quote">"{testimonial.quote}"</p>
                <div className="author-container">
                  <img src={testimonial.image} alt={testimonial.author} className="author-image" /> {/* Imagen del autor */}
                  <p className="author">
                    <strong>{testimonial.author}</strong>, {testimonial.role}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>

      <ScrollLink to="contact">
        {/* Botón animado con la misma animación pero diferente velocidad */}
        <motion.div
          className="button-container"
          initial={{ opacity: 0, x: -50 }}
          animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -70 }}
          transition={{ duration: 1.5, delay: 1 }}
        >
          <motion.button
            className="testimonials__button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Lográ resultados similares
          </motion.button>
        </motion.div>
      </ScrollLink>
    </div>
  );
};

export default Testimonials;
