import React, { useState, useEffect } from 'react';
import './Contact.css'; // Importar el archivo CSS correspondiente
import { motion } from 'framer-motion';
import Cal from './Cal';

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const contactElement = document.querySelector('.contact');
      if (contactElement && !animationCompleted) {
        const bounding = contactElement.getBoundingClientRect();
        const visible = bounding.top <= window.innerHeight && bounding.bottom >= 0;
        setIsVisible(visible);
        if (visible) {
          setAnimationCompleted(true); // Marcamos la animación como completada
          window.removeEventListener('scroll', handleScroll); // Eliminamos el listener de scroll
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationCompleted]);


  return (
    <div className="contact">
      <motion.h1
        className="results__title2"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -70 }}
        transition={{ duration: 1, delay: 0.6 }}
      >
         Agenda una <span className="animate-text-gradient1">Reunión:</span> 
      </motion.h1>
      <motion.div
         initial={{ opacity: 0, y: 20 }}
         animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
         transition={{ duration: 1, delay: 0.9 }}
      >
        <div className="cal-container">
          <Cal/>
        </div>
      </motion.div>
    </div>
  );
}

export default Contact;
