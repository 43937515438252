import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Services.css';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import imagen from './Tableweb.png'; // Importar la imagen
import imagen2 from './imagen2.svg'; // Importar la imagen
import imagen3 from './imagen3.svg'; // Importar la imagen

let slider; // Definir la variable slider fuera del componente



const Services = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const servicesElement = document.querySelector('.services');
      if (servicesElement && !animationCompleted) {
        const bounding = servicesElement.getBoundingClientRect();
        const visible = bounding.top <= window.innerHeight && bounding.bottom >= 0;
        setIsVisible(visible);
        if (visible) {
          setAnimationCompleted(true); // Marcamos la animación como completada
          window.removeEventListener('scroll', handleScroll); // Eliminamos el listener de scroll
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationCompleted]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <=1000 ); // Cambiar a true si el ancho de la ventana es menor o igual a 768px
    };

    handleResize(); // Ejecutar una vez al principio para establecer el estado inicial
    window.addEventListener('resize', handleResize); // Escuchar cambios en el tamaño de la ventana
    return () => {
      window.removeEventListener('resize', handleResize); // Limpiar el event listener al desmontar el componente
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500, // Velocidad de transición rápida y suave
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Cambiado a true para habilitar el autoplay
    autoplaySpeed: 7000, // Intervalo de cambio de tarjeta
    pauseOnHover: true,
    fade: true, // Efecto de desvanecimiento
    arrows: false, // Ocultar las flechas de navegación
    draggable: false, // Deshabilitar la navegación por arrastre
    swipe: false, // Deshabilitar el deslizamiento táctil
    afterChange: (index) => setCurrentSlide(index)
  };

  const renderNumberColumn = (number, index) => {
    const isActive = index === currentSlide;

    return (
      <div className="number-column" onClick={() => goToSlide(index)} key={index}>
        <div className={`number ${isActive ? 'active' : ''}`}>
          <div className={`square ${isActive ? 'active-square' : ''}`}>{number}</div>
        </div>
      </div>
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    slider.slickGoTo(index);
  };
  
  const renderDesktopView = () => {
    return (
      <div className="services desktop">
        {/* Contenido para la vista de escritorio */}
        <motion.h1
                className="services__title"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -70 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
       ¿Cómo funciona el servicio de <span className="animate-text-blue">Cresify</span>?
          </motion.h1>
          <motion.div
          className="carousel-container2"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -50 }}
          transition={{ duration: 1.3, delay: 0.7 }}
        >
          <Slider ref={(c) => (slider = c)} {...settings}>
            <div className="carousel-card">
              <div className="content">
                <div className="number-row">
                  {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
                </div>
                <div className="title-text-column">
                  <h2 className="title"><strong>Prospectamos</strong> con precisión y enfoque estratégico:                  </h2>
                  <p className="text">Identificamos a los <strong>Clientes Ideales</strong> para tu empresa, analizando patrones comunes, desde las tecnologías que utilizan hasta las regulaciones que deben cumplir. </p>
                  <p className="text">Validamos toda la información con nuestra <strong>Tecnología Propia</strong> de waterfall. Utilizamos plataformas como Sales Navigator, Apollo, Clay, Lemlist, Lusha y ZoomInfo para asegurar resultados precisos.</p>
                </div>
                <div className="image-column">
                  <img src={imagen} alt="Imagen" className="image" style={{borderTopRightRadius: '30px', borderBottomRightRadius: '30px'}} />
                </div>
              </div>
            </div>
         {/* Agregar las demás tarjetas */}
         <div className="carousel-card">
            <div className="content">
              <div className="number-row">
                {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
              </div>
              <div className="title-text-column">
                <h2 className="title"><strong>Diseñamos</strong> una estrategia multicanal adaptada a tu industria:</h2>
                <p className="text">Creamos una estrategia personalizada que incluye cold email, <strong>Llamadas en Frío</strong> y mensajes directos en LinkedIn. Según el caso, integramos nuestra tecnología publicitaria. </p>
                <p className="text"> Nos encargamos del <strong>Diseño de la Campaña</strong>, del copywriting y preparamos todos los recursos necesarios. </p>
              </div>
              <div className="image-column">
                <img src={imagen2} alt="Imagen" className="image" style={{borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }} />
              </div>
            </div>
          </div>
          <div className="carousel-card">
            <div className="content">
              <div className="number-row">
                {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
              </div>
              <div className="title-text-column">
                <h2 className="title"><strong>Ejecutamos</strong>, iteramos y escalamos la estrategia multicanal:</h2>
                <p className="text">Iniciamos con una <strong>Prueba</strong> en un grupo seleccionado, monitoreando resultados para detectar fallas y optimizar el proceso. Repetimos hasta que esté perfectamente ajustado.</p>
               
                <p className="text">Luego, ampliamos los <strong>Sectores de Prospección</strong>. Nuestro enfoque es generar valor para el cliente, evitando el spam, con el objetivo de convertir.</p>
              </div>
              <div className="image-column">
                <img src={imagen3} alt="Imagen" className="image" style={{borderTopRightRadius: '30px', borderBottomRightRadius: '30px'}} />
              </div>
            </div>
          </div>
          
          </Slider>
        </motion.div>
        <ScrollLink
                 to="contact"
            >
        <motion.div
        className="button-container"
        initial={{ opacity: 0, x: -50 }}
        animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -70 }}
        transition={{ duration: 2, delay: 1.5 }}
      >
        <motion.button
          className="testimonials__button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Aplica nuestro servicio en tu empresa
        </motion.button>
      </motion.div>
      </ScrollLink>
        </div>
   
    ); 
  };

  const renderMobileView = () => {
    

    const settings = {
      dots: false,
      infinite: true,
      speed: 250, // Velocidad de transición rápida y suave
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, // Cambiado a true para habilitar el autoplay
      autoplaySpeed: 3500, // Intervalo de cambio de tarjeta
      pauseOnHover: true,
      fade: true, // Efecto de desvanecimiento
      arrows: false, // Ocultar las flechas de navegación
      swipe: false, // Deshabilitar el deslizamiento táctil
      afterChange: (index) => setCurrentSlide(index)
    };
  
    return (
      <div className="services mobile">
          <motion.h1
                className="services__title"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -70 }}
                transition={{ duration: 0.5, delay: 0.3 }}
            >
         ¿Cómo funciona el servicio de <span className="animate-text-blue">Cresify</span>?
         
        </motion.h1>
        <motion.div
  className="carousel-container2"
  style={{ width: '95%' }}
  initial={{ opacity: 0, x: -50 }}
  animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -50 }}
  transition={{ duration: 1, delay: 0.5 }}
>
  <Slider ref={(c) => (slider = c)} {...settings}>
    {/* Tarjeta 1 */}
    <div className="carousel-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width: '300px', height: 'auto', borderRadius: '15px', overflow: 'hidden' }}>
      <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="image-column" style={{ borderTopRightRadius: '17px', borderTopLeftRadius: '17px', overflow: 'hidden' }}>
          <img src={imagen} alt="Imagen" className="image" style={{ width: '100%', height: '200px' }} />
        </div>
        <div className="title-text-column" style={{ marginTop: '10px' }}>
        <h2 className="title"><strong>Prospectamos</strong> con precisión y enfoque estratégico:                  </h2>
                  <p className="text">Identificamos a los <strong>Clientes Ideales</strong> para tu empresa, analizando patrones comunes, desde las tecnologías que utilizan hasta las regulaciones que deben cumplir. </p>
                  <p className="text">Validamos toda la información con nuestra <strong>Tecnología Propia</strong> de waterfall. Utilizamos plataformas como Sales Navigator, Apollo, Clay, Lemlist, Lusha y ZoomInfo para asegurar resultados precisos.</p>   
        </div>
        <div className="number-row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
          {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
        </div>
      </div>
    </div>
    {/* Tarjeta 2 */}
    <div className="carousel-card" style={{ margin: '0 auto', maxWidth: '300px'}}>
      <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="image-column" style={{ borderTopRightRadius: '17px', borderTopLeftRadius: '17px', overflow: 'hidden' }}>
          <img src={imagen2} alt="Imagen" className="image" style={{ width: '100%', height: '200px' }} />
        </div>
        <div className="title-text-column" style={{ marginTop: '10px' }}>
        <h2 className="title"><strong>Diseñamos</strong> una estrategia multicanal adaptada a tu industria:</h2>
                <p className="text">Creamos una estrategia personalizada que incluye cold email, <strong>Llamadas en Frío</strong> y mensajes directos en LinkedIn. Según el caso, integramos nuestra tecnología publicitaria. </p>
                <p className="text"> Nos encargamos del <strong>Diseño de la Campaña</strong>, del copywriting y preparamos todos los recursos necesarios. </p>
        </div>
        <div className="number-row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
          {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
        </div>
      </div>
    </div>
    {/* Tarjeta 3 */}
    <div className="carousel-card" style={{ width: '300px', height: 'auto', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
      <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="image-column" style={{ borderTopRightRadius: '17px', borderTopLeftRadius: '17px', overflow: 'hidden' }}>
          <img src={imagen3} alt="Imagen" className="image" style={{ width: '100%', height: '200px' }} />
        </div>
        <div className="title-text-column" style={{ marginTop: '10px' }}>
        <h2 className="title"><strong>Ejecutamos</strong>, iteramos y escalamos la estrategia multicanal:</h2>
                <p className="text">Iniciamos con una <strong>Prueba</strong> en un grupo seleccionado, monitoreando resultados para detectar fallas y optimizar el proceso. Repetimos hasta que esté perfectamente ajustado.</p>
               
                <p className="text">Luego, ampliamos los <strong>Sectores de Prospección</strong>. Nuestro enfoque es generar valor para el cliente, evitando el spam, con el objetivo de convertir.</p>
         
        </div>
        <div className="number-row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
          {[1, 2, 3].map((number, index) => renderNumberColumn(number, index))}
        </div>
      </div>
    </div>
   
  </Slider>
  </motion.div>
  <ScrollLink
                 to="contact"
            >
  <motion.div
        className="button-container2"
        initial={{ opacity: 0, x: -50 }}
        animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -70 }}
        transition={{ duration: 2, delay: 1.5 }}
      >
        <motion.button
          className="testimonials__button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Aplica nuestro servicio en tu empresa
        </motion.button>
      </motion.div>
      </ScrollLink>
  
</div>
    
    );
  };

  return (
    <>
      {isMobile ? renderMobileView() : renderDesktopView()}
    </>
  );
};

export default Services;
