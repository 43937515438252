import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Faqs.css'; // Asegúrate de importar el archivo CSS correspondiente

const FaqCard = ({ question, answer, isOpen, onClick }) => {
  return (
    <motion.div
      className={`faq-card ${isOpen ? 'open' : ''}`}
      onClick={onClick}
      initial={{ opacity: 0, x: -50 }} // Animación de entrada desde la izquierda
      animate={{ opacity: 1, x: 0 }} // Animación de aparición
      transition={{ duration: 0.3 }} // Duración de la animación
    >
      <h2>{question}</h2>
      <motion.div
        initial={false} // No animar en la carga inicial
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }} // Animar la altura y la opacidad
        transition={{ duration: 0.3 }} // Duración de la animación
        className="faq-answer"
        dangerouslySetInnerHTML={{ __html: answer }} // Renderizar HTML en la respuesta
      />
      <motion.button
        className={`faq-button ${isOpen ? 'open' : 'closed'}`} // Clase condicional para cambiar el estilo del botón
        whileHover={{ scale: 1.1 }} // Animación al pasar el ratón sobre el botón
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#8a8a8a"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </motion.button>
    </motion.div>
  );
};

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null); // Índice de la tarjeta activa
  const [isVisible, setIsVisible] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const faqsElement = document.querySelector('.faqs-container');
      if (faqsElement && !animationCompleted) {
        const bounding = faqsElement.getBoundingClientRect();
        const visible = bounding.top <= window.innerHeight && bounding.bottom >= 0;
        setIsVisible(visible);
        if (visible) {
          setAnimationCompleted(true); // Marcar la animación como completada
          window.removeEventListener('scroll', handleScroll); // Eliminar el listener de scroll
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationCompleted]);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Si la tarjeta está abierta, la cerramos; de lo contrario, la abrimos
  };

  const faqData = [
    {
       question: "1. ¿Cuáles son los resultados mensuales promedio que obtienen?",
    answer: `
      <p>En la industria de tecnología, nuestros resultados promedio durante un período de <strong>3 meses</strong> son:</p>
      <ul style="list-style-type: none; padding-left: 0;">
        <li><span style="color: #4723fd;">1.</span> <strong>Prospectos:</strong> <strong>880</strong></li>
        <li><span style="color: #4723fd;">2.</span> <strong>Conexiones:</strong> <strong>520 (59%)</strong></li>
        <li><span style="color: #4723fd;">3.</span> <strong>Respuestas:</strong> <strong>370 (71%)</strong></li>
        <li><span style="color: #4723fd;">4.</span> <strong>Reuniones:</strong> <strong>160 (44%)</strong></li>
        <li><span style="color: #4723fd;">5.</span> <strong>Cierres:</strong> <strong>35 (22%)</strong></li>
      </ul>
      <p>Estos resultados varían según la especificidad del grupo de prospectos y la industria.</p>
    `,
    },
    {
      question: "2. ¿En cuánto tiempo veré resultados?",
      answer: `
        <p>En Cresify, empezamos a mostrar resultados desde el <strong>primer mes</strong>. Proponemos un plan inicial de <strong>6 meses</strong>, con los primeros <strong>3 meses</strong> críticos para establecer una base sólida.</p>
        <p>Tene en cuenta que los resultados dependen de factores como:</p>
        <ul style="list-style-type: none; padding-left: 0;">
          <li><span style="color: #4723fd;">1.</span> <strong>Solidez</strong> de tu imagen empresarial.</li>
          <li><span style="color: #4723fd;">2.</span> <strong>Tracción</strong> del público objetivo.</li>
          <li><span style="color: #4723fd;">3.</span> <strong>Tamaño</strong> del territorio comercial.</li>
          <li><span style="color: #4723fd;">4.</span> <strong>Red de contactos</strong> existente.</li>
          <li><span style="color: #4723fd;">5.</span> <strong>Claridad</strong> de la oferta de valor.</li>
          <li><span style="color: #4723fd;">6.</span> <strong>Proceso</strong> interno de ventas.</li>
          <li><span style="color: #4723fd;">7.</span> <strong>Variedad</strong> de servicios y precios.</li>
          <li><span style="color: #4723fd;">8.</span> <strong>Tecnología</strong> y la industria en cuestión.</li>
          <li><span style="color: #4723fd;">9.</span> <strong>Seguimiento</strong> de tu fuerza de ventas a los leads entregados.</li>
        </ul>
      `,
    },
    {
      question: "3. ¿Cómo sé si es conveniente externalizar la captación de leads de mi empresa B2B?",
    answer: `
      <p>Externalizar la captación de leads puede ser beneficioso si:</p>
      <ul style="list-style-type: none; padding-left: 0;">
        <li><span style="color: #4723fd;">1.</span> <strong>Reduce</strong> el tiempo que tu equipo dedica a esta tarea.</li>
        <li><span style="color: #4723fd;">2.</span> <strong>Aprovecha</strong> la experiencia y tecnología especializada que no tienes in-house.</li>
        <li><span style="color: #4723fd;">3.</span> <strong>Disminuye</strong> costos en personal y recursos.</li>
        <li><span style="color: #4723fd;">4.</span> <strong>Permite</strong> que tus vendedores se concentren en cerrar ventas en lugar de buscar prospectos.</li>
      </ul>
    `,
    },
    {
      question: "4. ¿Cómo es el proceso de contratación?",
      answer: `
        <ol style="list-style-type: none; padding-left: 0;">
          <li><span style="color: #4723fd;">1.</span> <strong>Reunión de asesoría:</strong> Evaluamos tu situación y analizamos cómo captas clientes actualmente.</li>
          <li><span style="color: #4723fd;">2.</span> <strong>Estrategia personalizada:</strong> Diseñamos una estrategia adaptada a tus necesidades y la presentamos.</li>
          <li><span style="color: #4723fd;">3.</span> <strong>Revisión y firma:</strong> Ajustamos detalles, firmamos el contrato y completas un cuestionario.</li>
          <li><span style="color: #4723fd;">4.</span> <strong>Ejecución:</strong> Comenzamos la implementación de la estrategia.</li>
        </ol>
      `,
    },
    {
      question: "5. ¿Cómo garantizan la calidad de los prospectos generados?",
      answer: `
        <p>Garantizamos la calidad de los prospectos mediante <strong>nuestra tecnología de waterfall </strong>, que combina bases de datos dinámicas y estáticas con un proceso de validación y personalización manual.</p>
      `,
    },
  ];

  return (
    <div className="faqs-container">
      <motion.h1
        className="results__title2"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -50 }}
        transition={{ duration: 1, delay: 0.6 }} // Ajustar la duración y el retraso del título
      >
        <span className="animate-text-blue">Consultas y Dudas</span> Frecuentes
      </motion.h1>
      <div className="title-divider" />
      <div className="faq-row">
        {faqData.map((faq, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: -50 }} // Animación de entrada desde arriba
            animate={isVisible ? { opacity: 1, y: 0 } : {}} // Animar solo si es visible
            transition={{ duration: 0.7, delay: 0.7 + index * 0.2 }} // Ajustar la duración y el retraso de las tarjetas
          >
            <FaqCard
              question={faq.question}
              answer={faq.answer}
              isOpen={index === activeIndex}
              onClick={() => toggleAnswer(index)}
            />
          </motion.div>
        ))}
      </div>
      <p className="copyright-text" style={{ color: '#8a8a8a', fontWeight: 'bold', fontFamily: 'Poppins' }}>Creado con 💖 por Cresify.</p>
      <p className="copyright-text" style={{ color: '#8a8a8a', fontWeight: 'bold', fontFamily: 'Poppins' }}>2024 Cresify © copyright.</p>
    </div>
  );
};

export default Faqs;
