import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion'; // Importa useAnimation
import './PricingBox.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link as ScrollLink } from 'react-scroll';


const PricingBox = () => {
  const controls = useAnimation(); // Controlador de animaciones
  const titleControls = useAnimation(); // Controlador para el título

  useEffect(() => {
    controls.start({ opacity: 1, scale: 1 });
    titleControls.start({ opacity: 1, x: 0 });
  }, [controls, titleControls]);

  return (
    <div>
      {/* Título animado */}
      <motion.h1
        className="pricing-title"
        initial={{ opacity: 0, x: -50 }} // Animación inicial
        animate={titleControls} // Usa los controles para animaciones
        transition={{ duration: 1.5, delay: 0.5 }} // Duración y retraso
      >
        <span className="animate-text-blue">Precio simple,</span> valor inigualable.
      </motion.h1>

      {/* Contenedor principal */}
      <motion.div
        className="pricing-container"
        initial={{ opacity: 0, x: -50 }}
        animate={controls}
        transition={{ duration: 1.5, delay: 1 }}
      >
        {/* Columna Izquierda */}
        <div className="pricing-left">
          <h1>$2500</h1>
          <p>por mes</p>
          <ScrollLink
                 to="contact"
            >
          <button className="pricing-button">Multiplica tus clientes</button>
          </ScrollLink>
        </div>

        {/* Columna Derecha */}
        <div className="pricing-right">
          <ul className="checklist">
            <li><FaCheckCircle className="check-icon" /> +20 reuniones mensuales con clientes potenciales.</li>
            <li><FaCheckCircle className="check-icon" /> Equipo experto para ejecutar tu campaña.</li>
            <li><FaCheckCircle className="check-icon" /> Tecnología propia para resultados precisos.</li>
            <li><FaCheckCircle className="check-icon" /> +6X retorno sobre inversión (ROI).</li>
          </ul>
          <p className="note">
            Aunque la mayoría de nuestros servicios cuestan 2500 USD al mes, algunos servicios pueden requerir una inversión mensual mayor o menor.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default PricingBox;