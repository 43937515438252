import React from 'react';
import Slider from 'react-infinite-logo-slider';
import { motion } from 'framer-motion';
import './Carrousel.css';

const Carrousel = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 1 } },
  };

  return (
    <motion.div
      className="carousel-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <Slider
        width="325px"
        duration={30}
        pauseOnHover={true}
        blurBorders={false}
        blurBoderColor={'#fff'}
      >
        <Slider.Slide>
          <img src="/logo3.svg" alt="any3" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/logo4.svg" alt="any4" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/logo5.svg" alt="any5" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/logo6.svg" alt="any6" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/logo7.svg" alt="any7" className="w-36" />
        </Slider.Slide>

        <Slider.Slide>
          <img src="/logo9.svg" alt="any9" className="w-36" />
        </Slider.Slide>

        <Slider.Slide>
          <img src="/logo11.svg" alt="any11" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/logo12.svg" alt="any9" className="w-36" />
        </Slider.Slide>

        <Slider.Slide>
          <img src="/logo13.svg" alt="any11" className="w-36" />
        </Slider.Slide>
      </Slider>
    </motion.div>
  );
};

export default Carrousel;
