// Importar tu CSS aquí
import React, { useState, useEffect } from 'react';
import './Results.css';
import { motion } from 'framer-motion';
import backgroundImage1 from './image1.jpg';
import backgroundImage2 from './image2.jpg';
import backgroundImage3 from './image3.jpg';
import { Link as ScrollLink } from 'react-scroll';

const Results = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const resultsElement = document.querySelector('.results');
      if (resultsElement && !animationCompleted) {
        const bounding = resultsElement.getBoundingClientRect();
        const visible = bounding.top <= window.innerHeight && bounding.bottom >= 0;
        setIsVisible(visible);
        if (visible) {
          setAnimationCompleted(true); // Marcamos la animación como completada
          window.removeEventListener('scroll', handleScroll); // Eliminamos el listener de scroll
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationCompleted]);

  return (
    <div className="results">
      <motion.h1
        className="results__title"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -70 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
       <span className="animate-text-gradient">Resultados medibles:</span> más reuniones, más prospectos, más ventas
       
      </motion.h1>
      <div className="cards">
        <AnimatedCard
          image={backgroundImage1}
          number={20}
          delay={0}
          isVisible={isVisible}
          unit=""
          subtitle={<span><strong>Reuniones</strong>  Mensuales </span>}
        />
        <AnimatedCard
          image={backgroundImage2}
          number={6}
          delay={0.5}
          isVisible={isVisible}
          unit="X"
          subtitle={<span><strong>Retorno</strong> de Inversión</span>}
        />
        <AnimatedCard
          image={backgroundImage3}
          number={25}
          delay={1}
          isVisible={isVisible}
          unit="%"
          subtitle={<span> <strong>Tasa de cierre</strong> de ventas</span>}
        />
      </div>
      <ScrollLink
                 to="contact"
            >
      <motion.div
        className="button-container"
        initial={{ opacity: 0, x: -50 }}
        animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -70 }}
        transition={{ duration: 2, delay: 1.5 }}
      >
        <motion.button
          className="testimonials__button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Conseguí los mismos resultados
        </motion.button>
      </motion.div>
      </ScrollLink>

    </div>
  );
};

const AnimatedCard = ({ image, number, delay, isVisible, unit, subtitle }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShouldAnimate(true);
    }
  }, [isVisible]);

  return (
    <motion.div
      className="card"
      style={{ backgroundImage: `url(${image})`, backgroundPosition: 'center' }} // Ajusta la posición del fondo al centro
      animate={{ opacity: shouldAnimate ? 1 : 0, scale: shouldAnimate ? 1 : 0 }}
      transition={{ duration: 1, delay }}
    >
      <div className="card__content">
        <h2 className="card__title">
          +
          <AnimateNumber to={number} shouldAnimate={shouldAnimate} />
          {unit}
        </h2>
        <p className="card__subtitle">
          {subtitle}
        </p>
      </div>
    </motion.div>
  );
};

const AnimateNumber = ({ to, shouldAnimate }) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    let interval;
    let step = 1;
    if (to > 100) {
      step = 4;
    } else if (to > 50) {
      step = 5;
    }

    if (shouldAnimate) {
      interval = setInterval(() => {
        setNumber(prevNumber => {
          const nextNumber = prevNumber + step;
          return nextNumber <= to ? nextNumber : to;
        });
      }, 75); // Acelera la velocidad de la carga de números
    } else {
      setNumber(0);
    }

    return () => clearInterval(interval);
  }, [to, shouldAnimate]);

  return <>{number}</>;


};

export default Results;
